import "../App.css";
import { Link } from "react-router-dom";

export const Footer = (props) => {
  return (
    <div id="footer">
      <div className="privacy-service">
        <div className="privacy-pol">
          <div className="celll">
            <Link to="/privacy"> PRIVACY POLICY</Link>
          </div>
          <div className="celldivider">|</div>
          <div className="cellr">
            <Link to="/about">ABOUT</Link>
          </div>
         
          <div className="celldivider">|</div>
          <div className="cellr">
            <Link to="/terms">TERMS AND CONDITIONS</Link>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2022 Fine Mining LLC. All Rights Reserved.</p>
      </div>
    </div>
  );
};
