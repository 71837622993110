import logo from '../assets/blackLogo.png'
import appstore from '../assets/appstore.svg'
import playstore from '../assets/google-play.png'
import about1 from '../assets/about_1.png'
import about2 from '../assets/about_2.jpg'
import about3 from '../assets/about_3.jpg'
import fineshot from '../assets/previewed.png'
import blackIcon from '../assets/blackIcon.png'
import Fade from 'react-reveal/Fade';
import { RiSecurePaymentFill } from "react-icons/ri";
import { FaPeopleCarry } from "react-icons/fa";
import { Footer } from './footer'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import { Col, Row, Container, Button, Navbar, Nav, Card } from "react-bootstrap";

export const AboutFine = (props) => {

  let iconStyles = { color: "white", fontSize: "2.5em" };

  return (
    <div className='content'>    
    <div className="about">
      <div className='bgimage'>
      <Navbar collapseOnSelect expand="md" variant="dark" static-top >
        <Container>
          <Navbar.Brand href="/" mx-auto>
            <img
              src={logo}
              padding-top="5"
              max-width="200"
              height="45"
              className="d-inline-block align-left"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav  className="ms-auto">
            <Nav.Link href="/"> <div className='link-text'>HOME</div></Nav.Link>
            <Nav.Link href="/faq"><div className='link-text'>FAQ</div></Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        <div className='text-center prem-title' data-reveal-delay="200">
          <Fade bottom>
            <h1>FINE MINING</h1>
          </Fade>
          <Fade bottom>
          <h2><span>THE PREMIER COMPUTING HARDWARE MARKETPLACE</span></h2>
          </Fade>
          <Fade bottom delay={1100}>
          <a href='#aboutus'>
          <button class="button-86" role="button">LEARN MORE</button>
          </a>
          </Fade>
          </div> 
        </div>
        <Container className='about-us' id='aboutus'>
          <br />
          <Fade bottom delay={1500}>
          <h2>ABOUT FINE MINING</h2>
          </Fade>
          {/* <br /><br /> */}
          <Container className='about-item'>
            <Row>
           
              <Col xs={{ span: 12}} md={{ span: 4}}>
                <Fade delay={200}>    
                  <img 
                  id='fine-icon'
                  src={blackIcon}
                  height="2.5em !important"
                  />
                  <br/>
                  <h4>A Safe Marketplace</h4>
                  <br/>
                  <h5>                
                    Fine Mining provides a marketplace equally safe for both buyers and sellers offering a wide selection of GPUs, PSUs, and Assembled Computers
                  </h5>
                </Fade>
              </Col>
                            
              <Col xs={{ span: 12}} md={{ span: 4}}>
                <Fade delay={500}>
                  <RiSecurePaymentFill style={iconStyles}/>
                  <br />
                    <h4>How You are Protected</h4>
                    <br/>
                    <h5>                
                    Each product is verified for authenticity, condition, and functionality by our experts to ensure you get what you ordered
                    </h5>
                </Fade>     
              </Col> 
              <Col xs={{ span: 12}} md={{ span: 4}}>
              <Fade delay={800}>
              <FaPeopleCarry style={iconStyles}/>
              <br />
                <h4>Why Us?</h4>
                <br/>
                <h5>                
                Buy and sell your products with peace of mind, fast turnaround times, and get products for the best market prices in the United States 
                </h5>
                </Fade>
              </Col>
              
            </Row>
          </Container>
          <Container id='fine-screen'>
            <Fade delay={1500}>
            <img src={fineshot}/>
            </Fade>  
          </Container>
          <Fade delay={1700}>
          <Row>
            <Col> 
            <a className='float-end' href="https://play.google.com/store/apps/details?id=com.mjbinghamton.pick_x&hl=en_US&gl=US"><img id='play' src={playstore} /></a>
       
          </Col>
           <Col>
            <a className='float-start' href="https://apps.apple.com/us/app/fine-mining/id1615222520"><img id='app' src={appstore} /></a>
           </Col>
          </Row>
          </Fade>  
          </Container>

          {/* <Container className='products d-none d-lg-block"' >
            <br />
            <Fade bottom delay={500}>
            <h2>Products Currently Available to Buy and Sell</h2>
            </Fade>
            <br />
            </Container>
            <Fade bottom delay={1000}>
            <Container className='accordions'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>GPUs</Accordion.Header>
                <Accordion.Body>
                 NVIDIA GeForce 3090, NVIDIA GeForce 3090 Ti, NVIDIA GeForce 3080, NVIDIA GeForce 3080 Ti, NVIDIA GeForce 3070, NVIDIA GeForce 3070 Ti, NVIDIA GeForce 3060, NVIDIA GeForce 3060 Ti, NVIDIA GeForce 3050,
                 NVIDIA GeForce 2080, NVIDIA GeForce 2080 Ti, NVIDIA GeForce 2080 Super, NVIDIA GeForce 2070, NVIDIA GeForce 2070 Super, NVIDIA GeForce 2060, NVIDIA GeForce 2060 Super  
                 
                </Accordion.Body>
            
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Power Supply Units</Accordion.Header>
                <Accordion.Body>
                EVGA SuperNOVA 850 T2, EVGA SuperNOVA 1000 T2, EVGA SuperNOVA 1600 T2, EVGA SuperNOVA 650 P5, EVGA SuperNOVA 750 P5, EVGA SuperNOVA 850 P6, EVGA SuperNOVA 650 P2,  
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Assembled Computers</Accordion.Header>
                <Accordion.Body>
                  Goldshell KD5, Goldshell CK5, Goldshell LT5, 
                  Goldshell KD2, Goldshell KD-Box, Goldshell ST-Box, Goldshell CK-Box, Goldshell HS-Box
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </Container> */}
            {/* </Fade> */}
            <Fade bottom delay={500}>
            <Container className='contact-us'>
            <h2>Need more info?</h2>
            <br></br>
            <Link to="/contact">Contact Us</Link>
            </Container>
            </Fade>
        </div>
     <Footer></Footer>
     </div>
  )
}
