import { useState } from 'react'
import { Footer } from './footer'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div className="content">
        <div className="faq">
          <br />
          <br />
          <h1>Contact us</h1>
          <h2>Please contact us if you cannot find an answer to your question.</h2>
          <br></br>
          <br />
          <br />
          <br />
          <div className='row'>
          <div id="one" className='col-4 col-offset-2'>
            <h3>Address</h3>
            <p>
                 <span>
                   <i className='fa fa-map-marker'></i> 
                 </span>
                 {props.data ? props.data.address : '  120 Hawley street STE 223 \n Binghamton, NY 13901'}
               </p>
            <h3>Phone</h3>
            <p>
                 <span>
                   <i className='fa fa-phone'></i> 
                 </span>{' '}
                 {props.data ? props.data.phone : ' (+1)3159330199'}
               </p>
            <h3>Email</h3>
            <p>
                 <span>
                   <i className='fa fa-envelope-o'></i> 
                 </span>{' '}
                 {props.data ? props.data.email : '   support@fine-mining.com'}
               </p>
          </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    // <div>
    //   <div id='contact'>
    //     <div className='container'>
    //       <div className='col-md-8'>
    //         <div className='row'>
    //           <div className='section-title'>
    //             <h2>Get In Touch</h2>
    //             <p>
    //               Please fill out the form below to send us an email and we will
    //               get back to you as soon as possible.
    //             </p>
    //           </div>
    //           <form name='sentMessage' validate onSubmit={handleSubmit}>
    //             <div className='row'>
    //               <div className='col-md-6'>
    //                 <div className='form-group'>
    //                   <input
    //                     type='text'
    //                     id='name'
    //                     name='name'
    //                     className='form-control'
    //                     placeholder='Name'
    //                     required
    //                     onChange={handleChange}
    //                   />
    //                   <p className='help-block text-danger'></p>
    //                 </div>
    //               </div>
    //               <div className='col-md-6'>
    //                 <div className='form-group'>
    //                   <input
    //                     type='email'
    //                     id='email'
    //                     name='email'
    //                     className='form-control'
    //                     placeholder='Email'
    //                     required
    //                     onChange={handleChange}
    //                   />
    //                   <p className='help-block text-danger'></p>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className='form-group'>
    //               <textarea
    //                 name='message'
    //                 id='message'
    //                 className='form-control'
    //                 rows='4'
    //                 placeholder='Message'
    //                 required
    //                 onChange={handleChange}
    //               ></textarea>
    //               <p className='help-block text-danger'></p>
    //             </div>
    //             <div id='success'></div>
    //             <button type='submit' className='btn btn-custom btn-lg'>
    //               Send Message
    //             </button>
    //           </form>
    //         </div>
    //       </div>
    //       <div className='col-md-3 col-md-offset-1 contact-info'>
    //         <div className='contact-item'>
    //           <h3>Contact Info</h3>
    //           <p>
    //             <span>
    //               <i className='fa fa-map-marker'></i> Address
    //             </span>
    //             {props.data ? props.data.address : '120 Hawley street STE 223 \n Binghamton, NY 13901'}
    //           </p>
    //         </div>
    //         <div className='contact-item'>
    //           <p>
    //             <span>
    //               <i className='fa fa-phone'></i> Phone
    //             </span>{' '}
    //             {props.data ? props.data.phone : 'loading'}
    //           </p>
    //         </div>
    //         <div className='contact-item'>
    //           <p>
    //             <span>
    //               <i className='fa fa-envelope-o'></i> Email
    //             </span>{' '}
    //             {props.data ? props.data.email : 'support at fine-mining.com'}
    //           </p>
    //         </div>
    //       </div>
          
    //     </div>
    //   </div>
    //   <Footer></Footer>
    // </div>
  )
}
