 import '../App.css'
 import appstore from '../assets/appstore.svg'
 import playstore from '../assets/google-play.png'
 import gremlin from '../assets/super_gremlin.mp4'
 import { Footer } from './footer'
 import { Link } from "react-router-dom";
import { Col, Row } from 'react-bootstrap'


 
export const Landing = (props) => {
  return (
    <div>
    <header id='header'>
      <div className='intro'>
        <div className='super-gremlin'>
          <Link to='/about'>
          <div
          dangerouslySetInnerHTML={{
            __html: `
            <video className="app__backgroundVideo" autoplay loop muted playsinline>
                <source src=${gremlin} type="video/mp4" />
                Your browser does not support the video tag.
            </video>`,
          }}
        />
          </Link>
        </div>
        <div className='intro-text'>
          <h1>
            {props.data ? props.data.title : 'Loading'}
          </h1>
        </div>
        <Row>
          <Col>
          <a className='float-end' href="https://play.google.com/store/apps/details?id=com.mjbinghamton.pick_x&hl=en_US&gl=US"><img id='play' src={playstore} /></a>
       
          </Col>
        <Col>
          <a href="https://apps.apple.com/us/app/fine-mining/id1615222520"><img id='app' src={appstore} /></a>
       
          </Col>
       
        </Row>
          
      </div>
    </header>
    <Footer/>
    </div>
  )
}
