import { Footer } from './footer'
export const FAQ = (props) => {
    return (
      <div className="content">
        <div className="faq">
          <h1>Frequently Asked Questions</h1>
          <h2>Please contact us if you cannot find an answer to your question.</h2>
          <br></br>
          <br />
          <br />
          <div id="one">
            <h3>How does it work?</h3>
            <p>
            When a bid and ask meet, the seller ships the computer hardware to our verification facility where we verify the authenticity, condition, and functionality of the computer hardware to ensure it matches the order requirements. After we confirm it's up to the buyers standards, the computer hardware is sent on the way to its new home.
            </p>
            <h3>How do I get paid?</h3>
            <p>
            Fine Mining uses PayPal for payouts, look out for our emails and you can expect your payout to arrive within 24 hours of verification to your PayPal account.
            </p>
            <h3>How long do I have to wait?</h3>
            <p>
            After we receive the computer hardware it will be verified and shipped out the following business day.
            </p>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  };
  