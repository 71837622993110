import { useState, useEffect } from 'react'
import { Landing } from './components/landing_page'
import { Footer } from './components/footer'
import { AboutFine } from './components/company_about'
import { FAQ } from './components/faq'
import {About} from './components/about';
import { Terms } from './components/terms'
import {Contact} from './components/contact'
import {PayPalLoading} from './components/paypalLoading'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing data={landingPageData.Header} />} />
        <Route path="/privacy" element={<About />}/>
        <Route path="/footer" element={<Footer />}/>
        <Route path="/about" element={<AboutFine />}/>
        <Route path="/faq" element={<FAQ />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/verifyPayPal" element={<PayPalLoading />}/>
      </Routes>
    </Router>
    // <>
    //   {/* This is the alias of BrowserRouter i.e. Router */}
    //   <Router>
    //     <Switch>
    //       {/* This route is for home component 
    //       with exact path "/", in component props 
    //       we passes the imported component*/}
    //       <Route exact path="/" component={Header} />
            
    //       {/* This route is for about component 
    //       with exact path "/about", in component 
    //       props we passes the imported component*/}
    //       <Route path="/footer" component={Footer} />
            
    //       {/* This route is for contactus component
    //       with exact path "/contactus", in 
    //       component props we passes the imported component*/}
    //       {/* <Route path="/contactus" component={ContactUs} /> */}
            
    //       {/* If any route mismatches the upper 
    //       route endpoints then, redirect triggers 
    //       and redirects app to home component with to="/" */}
    //       <Redirect to="/" />
    //     </Switch>
    //   </Router>
    // </>
    // <div>
    //   {/* <Navigation></Navigation> */}
    //   <Header data={landingPageData.Header} />
    //   <Footer />
    // </div>
  )
}

export default App
