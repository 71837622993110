import { Col, Row, Container, Button, Navbar, Nav, Card } from "react-bootstrap";
import fmLogo from '../assets/appbarLogo.png'
export const PayPalLoading = (props) => {

  let iconStyles = { color: "white", fontSize: "2.5em" };

  return (  
    <div className="verifyPayPal">
        <Container className='verifyPayPal' id='verifyPayPal'>
          <div className='text-center'>
            <h1><img src={fmLogo} alt="my-gif" /></h1>
            <h2>VERIFYING YOUR PAYPAL ACCOUNT</h2>
            <div class="spinner loading"></div>
          </div>
        </Container>
    </div>
  )
}
